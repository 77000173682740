body {
	margin: 0;
	margin-top: 100px;
	padding: 0;
}


.cards-2 {
	/* padding-top: "20px";
	padding-bottom: "10px"; */
	background-color: #f7fafd;
	text-align: center;
}

.cards-2 h2 {
	/* margin-bottom: "20px"; */
	font-style: Poppins;
}

.cards-2 .card {
	max-width: 22rem;
	margin-right: auto;
	/* margin-bottom: 4.75rem; */
	margin-left: auto;
	border: 0;
	background-color: transparent;
}


.cards-2 {
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #f7fafd;
	text-align: center;
}

.cards-2 h2 {
	margin-bottom: 3.25rem;
}

.cards-2 .card {
	max-width: 22rem;
	margin-right: auto;
	margin-bottom: 4.75rem;
	margin-left: auto;
	border: 0;
	background-color: transparent;
}

.cards-2 .fa-star {
	margin-right: 0.25rem;
	color: #ffb30f;
	font-size: 1.375rem;
}

.cards-2 .testimonial-line {
	width: 3rem;
	margin-bottom: 1rem;
	border-top: 0.125rem solid #e0e0e0;
}

.cards-2 .card-body {
	padding: 0;
}

.cards-2 .testimonial-text {
	margin-bottom: 0.625rem;
}

.cards-2 .testimonial-author {
	color: #333;
}

.cards-2 .image-container {
	margin-bottom: 5.25rem;
	padding: 2.5rem 2rem 0 2rem;
	border-radius: 0.5rem;
	background-color: #fff;
}

.cards-2 .image-container img {
	margin-right: 1rem;
	margin-bottom: 2.5rem;
	margin-left: 1rem;
}



/* Testimonials */
.cards-2 .card {
	display: inline-block;
	width: 18.125rem;
	max-width: 100%;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	vertical-align: top;
}

.cards-2 .card:first-of-type {
	margin-left: 0;
}

.cards-2 .card:last-of-type {
	margin-right: 0;
}

/* end of testimonials */







.testimonial-text {
	font-style: italic;
}

.testimonial-author {
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.5rem;
}