/* Privacy policy */

.terms-and-conditions-container {
    max-width: 800px;
    /* Adjust the maximum width as needed */
    margin: 0 auto;
    padding: 10px;
}

/* Add responsive styles */
@media only screen and (max-width: 600px) {
    .terms-and-conditions-container {
        padding: 10px;
    }

    h1 {
        font-size: 1.5em;
    }

    li {
        font-size: 0.9em;
    }
}